import fetch from "auth/FetchInterceptor";

const login = async (data) => {
  const result = await fetch({
    url: "/auth/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
  return result.data;
};

const forgotPassword = async (data) => {
  const result = await fetch({
    url: "auth/forgot-password",
    method: "post",
    headers: { "public-request": "true" },
    data: data,
  });
  return result.data;
};

const resetPassword = async (data) => {
  const result = await fetch({
    url: "auth/reset-password",
    method: "post",
    headers: { "public-request": "true" },
    data: data,
  });
  return result.data;
};

const authService = {
  login,
  forgotPassword,
  resetPassword,
};

export default authService;
