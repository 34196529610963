import fetch from 'auth/FetchInterceptor';

const createUser = async (sankhyaUser) => {
  const result = await fetch({
    url: `/user`,
    method: 'post',
    data: sankhyaUser,
  });
  return result.data;
};

const findUserByUuid = async (uuid) => {
  const result = await fetch({
    url: `/user/${uuid}`,
    method: 'get',
  });
  return result.data;
};

const findAllUser = async () => {
  const result = await fetch({
    url: `/user`,
    method: 'get',
  });
  return result.data;
};

const findAllUserByNameAndUsername = async (query) => {
  const result = await fetch({
    url: `/user/filter/${query}`,
    method: 'get',
  });
  return result.data;
};
const changePassword = async (uuid, currentPassword, newPassword) => {
  try {
    const data = { currentPassword, newPassword };
    const result = await fetch({
      url: `/user/change-password/${uuid}`,
      method: 'PATCH',
      data,
    });
    return result.data;
  } catch (error) {
    throw new Error(`Erro ao alterar senha: ${error.message}`);
  }
};
const updateUser = async (uuid, formData) => {
  const result = await fetch({
    url: `/user/${uuid}`,
    method: 'patch',
    data: formData,
  });
  return result.data;
};
const deleteUser = async (uuid) => {
  const result = await fetch({
    url: `/user/${uuid}`,
    method: 'delete',
  });
  return result.data;
};

const userService = {
  createUser,
  updateUser,
  findUserByUuid,
  findAllUser,
  findAllUserByNameAndUsername,
  changePassword,
  deleteUser,
};

export default userService;
