import {
  FilePptOutlined,
  PieChartOutlined,
  UserOutlined,
  InsertRowAboveOutlined,
  CalendarOutlined,
  TableOutlined,
  FileSearchOutlined,
  UsergroupAddOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboard",
    title: "sidenav.dashboard",
    path: "",
    breadcrumb: false,
    submenu: [
      {
        key: "dashboard",
        title: "sidenav.dashboard",
        path: `${APP_PREFIX_PATH}/dashboard`,
        breadcrumb: false,
        icon: PieChartOutlined,
        submenu: [],
      },
    ],
  },
  {
    key: "management",
    title: "sidenav.management",
    path: "",
    breadcrumb: false,
    submenu: [
      {
        key: "orders",
        title: "sidenav.orders",
        path: `${APP_PREFIX_PATH}/pedidos`,
        breadcrumb: false,
        icon: FilePptOutlined,
        submenu: [],
      },
      {
        key: "clients",
        title: "sidenav.clients",
        path: `${APP_PREFIX_PATH}/clientes`,
        breadcrumb: false,
        icon: UserOutlined,
        submenu: [],
      },
      {
        key: "products",
        title: "sidenav.products",
        path: `${APP_PREFIX_PATH}/produtos`,
        breadcrumb: false,
        icon: InsertRowAboveOutlined,
        submenu: [],
      },
      {
        key: "schedule",
        title: "sidenav.schedule",
        path: `${APP_PREFIX_PATH}/agenda`,
        breadcrumb: false,
        icon: CalendarOutlined,
        submenu: [],
      },
      {
        key: "tables",
        title: "sidenav.tables",
        path: ``,
        breadcrumb: true,
        icon: TableOutlined,
        submenu: [
          {
            key: "discount",
            title: "sidenav.discount",
            path: `${APP_PREFIX_PATH}/tabela-de-desconto`,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "price-list",
            title: "sidenav.price-list",
            path: `${APP_PREFIX_PATH}/tabela-de-preco`,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "reports",
        title: "sidenav.reports",
        path: `${APP_PREFIX_PATH}/relatorios`,
        breadcrumb: false,
        icon: FileSearchOutlined,
        submenu: [
          {
            key: "reports-best-selling-products",
            title: "sidenav.reports.best.selling.products",
            path: `${APP_PREFIX_PATH}/relatorios/produtos-mais-vendidos`,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "reports-commission",
            title: "sidenav.reports.comission",
            path: `${APP_PREFIX_PATH}/relatorios/comissao`,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "users",
        title: "sidenav.users",
        path: `${APP_PREFIX_PATH}/usuario`,
        breadcrumb: false,
        icon: UsergroupAddOutlined,
        submenu: [],
      },
      {
        key: "profile",
        title: "sidenav.profile",
        path: `${APP_PREFIX_PATH}/perfil`,
        breadcrumb: false,
        icon: ProfileOutlined,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
