import {
  DIR_LTR,
  NAV_TYPE_SIDE,
  SIDE_NAV_LIGHT,
} from "constants/ThemeConstant";
import { create } from "zustand";

export const useTheme = create((set) => ({
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#00CC62",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  toggleCollapsedNav: (navCollapsed) => {
    set({ navCollapsed });
  },
  onNavStyleChange: (sideNavTheme) => {
    set({ sideNavTheme });
  },
  onLocaleChange: (locale) => {
    set({ locale });
  },
  onNavTypeChange: (navType) => {
    set({ navType });
  },
  onTopNavColorChange: (topNavColor) => {
    set({ topNavColor });
  },
  onHeaderNavColorChange: (headerNavColor) => {
    set({ headerNavColor });
  },
  onMobileNavToggle: (mobileNav) => {
    set({ mobileNav });
  },
  onSwitchTheme: (currentTheme) => {
    set({ currentTheme });
  },
  onDirectionChange: (direction) => {
    set({ direction });
  },
}));
