import { create } from 'zustand';
import userService from 'services/user.service';

export const useUser = create((set) => ({
  loadingUser: false,
  users: [],
  user: null,
  errorUsers: null,

  createUser: async (user) => {
    set({ loadingUser: true });
    try {
      const result = await userService.createUser(user);
      return result;
    } catch (e) {
      set({
        errorUsers: e,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
  updateUser: async (uuid, formData) => {
    set({ loadingUser: true });
    try {
      const user = await userService.updateUser(uuid, formData);

      set({ user: user });
    } catch (e) {
      set({ errorUsers: e });
    } finally {
      set({ loadingUser: false });
    }
  },

  getUserByUuid: async (uuid) => {
    set({ loadingUser: true });
    try {
      const result = await userService.findUserByUuid(uuid);
      set({ user: result });
    } catch (e) {
      set({
        user: [],
        errorUsers: e,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
  getAllUser: async () => {
    set({ loadingUser: true });
    try {
      const result = await userService.findAllUser();

      set({ users: result });
    } catch (e) {
      set({
        users: [],
        errorUsers: e,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
  getAllUserByNameAndUsername: async (query) => {
    set({ loadingUser: true });
    try {
      const result = await userService.findAllUserByNameAndUsername(query);

      set({ users: result });
    } catch (e) {
      set({
        users: [],
        errorUsers: e,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
  changePassword: async (uuid, currentPassword, newPassword) => {
    set({ loadingUser: true });

    try {
      const userRes = await userService.changePassword(
        uuid,
        currentPassword,
        newPassword
      );
      console.log('res', userRes);
      set({
        user: userRes,
      });
    } catch (e) {
      set({ errorUsers: e });
    } finally {
      set({ loadingUser: false });
    }
  },

  deleteUser: async (uuid) => {
    set({ loadingUser: true });
    try {
      const result = await userService.deleteUser(uuid);
      return result;
    } catch (e) {
      set({
        errorUsers: e,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
}));
