import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/pedidos`}
          component={lazy(() => import(`./orders`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/pedidos/add`}
          component={lazy(() => import(`./orders/add`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/pedidos/add/:orderUuid`}
          component={lazy(() => import(`./orders/add`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/clientes/add`}
          component={lazy(() => import(`./clients/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/clientes/add/:userUuid`}
          component={lazy(() => import(`./clients/add`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/clientes`}
          component={lazy(() => import(`./clients`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/usuario`}
          component={lazy(() => import(`./user`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/usuario/edit/:uuid`}
          component={lazy(() => import(`./profile`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/produtos`}
          component={lazy(() => import(`./products`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/produtos/:productUuid`}
          component={lazy(() => import(`./products/detail`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/agenda`}
          component={lazy(() => import(`./schedule`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/changelog`}
          component={lazy(() => import(`./changelog`))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/tabela-de-desconto`}
          component={lazy(() => import(`./tables/discount-table`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/tabela-de-preco`}
          component={lazy(() => import(`./tables/price-table`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/relatorios/produtos-mais-vendidos`}
          component={lazy(() => import(`./reports-best-selling-products`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/relatorios/comissao`}
          component={lazy(() => import(`./reports-commission`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/perfil`}
          component={lazy(() => import(`./profile`))}
        />

        <Redirect
          exact
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
